import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import Button from "react-bootstrap/Button"

import Logoicon from "../images/ok-logo.svg"

const Navigation = ({ menuVariant }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Navbar variant={menuVariant} expand="lg" className="container menu">
      <Link to="/" className="navbar-brand">
        <img className="logoicon mr-2" src={Logoicon} alt="logo" />
        {data.site.siteMetadata.title}
      </Link>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto main-menu">
          <Link to="/manikyur/" className="nav-link" activeClassName="active">
            Манікюр
          </Link>
          <Link to="/pedykyur/" className="nav-link" activeClassName="active">
            Педикюр
          </Link>
          <Link
            to="/depilyatsiya/"
            className="nav-link"
            activeClassName="active"
          >
            Депіляція
          </Link>
          <Link to="/kontakty/" className="nav-link" activeClassName="active">
            Контакти
          </Link>
          <Button
            href="tel:+380973081196"
            variant="danger"
            className="ml-lg-4 mt-4 mt-lg-0"
          >
            +38(097) 308-11-96
          </Button>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

Navigation.defaultProps = {
  menuVariant: `dark`,
}

export default Navigation
