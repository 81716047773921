import React from "react"
import PropTypes from "prop-types"
import { Link, useStaticQuery, graphql } from "gatsby"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Logoicon from "../images/ok-logo.svg"
// import FacebookChat from "../components/FacebookChat"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <footer>
      <Container className="pt-5">
        <Row className="text-muted mt-5">
          <Col lg={3} md={6} sm={12}>
            <h4>
              <img className="logoicon mr-2" src={Logoicon} alt="logo" />
              {data.site.siteMetadata.title}
            </h4>
            <p>
              Манікюр під кутикулу, апаратний педикюр і депіляція.
              <br />
              Прийом за попереднім записом в салоні "Le Beaute Studio", ЖК
              "Білий Шоколад", Білогородка.{" "}
              <a
                href="https://www.google.com/maps/place/Manikyur,+Pedykyur,+Depilyatsiya,+Vizazh/@50.3901428,30.2335994,17z/data=!3m1!4b1!4m5!3m4!1s0x40d4b54d1a0d6b09:0x760aaa163b879be8!8m2!3d50.3901428!4d30.2357881"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                Знайти на мапі
              </a>
            </p>
            <p>Через війну, нові записи не опрацьовуються.</p>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <h5>Робочі години</h5>
            <ul className="list-unstyled">
              <li>Понеділок: вихідний</li>
              <li>Вівторок: 9:00-18:00</li>
              <li>Середа: 9:00-18:00</li>
              <li>Четвер: 9:00-18:00</li>
              <li>П'ятниця: 9:00-18:00</li>
              <li>Субота: 9:00-18:00</li>
              <li>Неділя: вихідний</li>
            </ul>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <h5>Зв'язатися зі мною</h5>
            <p>
              <a
                href="https://www.facebook.com/OlyaKoval.NailSalon"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                fb.me/OlyaKoval.NailSalon
              </a>
              <br />
              <a
                href="https://www.instagram.com/ok_wax_nails/"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                @ok_wax_nails
              </a>
            </p>
          <h5>Моя нова локація</h5>
            <p><a
                href="https://olyakoval.ca"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                Olya Koval Nail Salon
              </a></p>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <h5>Корисні посилання</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/" className="text-muted">
                  Головна
                </Link>
              </li>
              <li>
                <Link to="/manikyur/" className="text-muted">
                  Манікюр
                </Link>
              </li>
              <li>
                <Link to="/pedykyur/" className="text-muted">
                  Педикюр
                </Link>
              </li>
              <li>
                <Link to="/depilyatsiya/" className="text-muted">
                  Депіляція
                </Link>
              </li>
              <li>
                <Link to="/kontakty/" className="text-muted">
                  Контакти
                </Link>
              </li>
              <li>
                <Link to="/legal/" className="text-muted">
                  Правила користування
                </Link>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="container py-4">
          <span className="text-muted">© {new Date().getFullYear()}</span>
        </Row>
      </Container>
      {/* <FacebookChat /> */}
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
